import React from 'react';
import { Card } from 'primereact/card';

const Instructions = () => {
  return (
    <Card style={{ padding: 0, margin: '10px' }}>
      <strong>Instructions</strong>
      <ol>
        <li>
          Verify your account information. Please note any changes in the
          comment field when submitting your order.
        </li>
        <li>
          Begin adding products to your cart by clicking on the brand name you
          wish to shop from.
        </li>
        <li>
          Enter the quantities that you wish to order in the Quantity column.
        </li>
        <li>Continue selecting products from all brands.</li>
        <li>
          To complete your order, click on the submit button in the top
          right-hand corner.
        </li>
        <li>
          After your order has been received you’ll receive an email
          confirmation.
        </li>
      </ol>
      <strong>Notes</strong>
      <ol>
        <li>
          Orders will ship to the address on file. If your address has changed
          please note the change in the comment field when submitting your
          order.
          <li>Once submitted no changes can be made to your order.</li>
          <li>
            Carts are NOT saved. Please complete your order in one session.
          </li>
          <li>
            Any questions please email{' '}
            <a href='mailto:sales@capstonepg.com'>sales@capstonepg.com</a>.
          </li>
        </li>
      </ol>
    </Card>

    //     </CardBody>
    // </Card>
  );
};

export default Instructions;
