import React, { Component, useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { confirmPopup } from 'primereact/confirmpopup';
import SignUpForm from '../SignUp';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import EditUserModal from './EditUserModal';

import { Toast } from 'primereact/toast';
class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.state = {
      loading: false,
      users: [],
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.users().on('value', (snapshot) => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;
    return (
      <div className='row'>
        <div className='col-1'></div>
        <div className='col-10'>
          {loading && <div>Loading ...</div>}
          <SignUpForm />
          <hr style={{ border: '2px solid black', width: '100%' }} />
          <UserList
            users={users}
            firebase={this.props.firebase}
            toast={this.toast}
          />
        </div>
        <div className='col'></div>
      </div>
    );
  }
}

const UserList = ({ users, firebase, toast }) => {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const roleTemplate = (rowData, column) => {
    if (rowData.isAdmin) {
      return <span>True</span>;
    } else {
      return <span>False</span>;
    }
  };

  const sendResetEmail = (e, { email }) => {
    confirmPopup({
      target: e.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        firebase
          .doPasswordReset(email)
          .then(() => {
            toast.current.show({
              severity: 'success',
              summary: 'Success!',
              detail: 'Reset Email Sent!',
            });
          })
          .catch((error) => {
            toast.current.show({
              severity: 'error',
              summary: 'Error!',
              detail: 'There was an issue sending Password Reset email!',
            });
          });
      },
      reject: () => {},
    });
  };

  const pwRestTemplate = (rowData, column) => {
    return (
      <Button onClick={(e) => sendResetEmail(e, rowData)}>
        send reset email
      </Button>
    );
  };
  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      List of Users
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Global Search'
        />
      </span>
    </div>
  );

  //  const paginatorLeft = (
  //    <Button type='button' icon='pi pi-refresh' className='p-button-text' />
  //  );
  //  const paginatorRight = (
  //    <Button type='button' icon='pi pi-cloud' className='p-button-text' />
  //  );

  const handleSubmit = (userData) => {
    toggle();

    firebase.user(userData.uid).update({
      active: userData.active,
      company: userData.company,
      phone: userData.phone,
      contact: userData.contact,
      email: userData.email,
      pricing: userData.pricing,
      terms: userData.terms,
      axNumber: userData.axNumber,
      isAdmin: userData.isAdmin,
    });
  };

  return (
    <div className='row'>
      <Toast ref={toast} position='bottom-right' />
      <EditUserModal
        toggle={toggle}
        modal={modal}
        user={selectedUser}
        handleSubmit={handleSubmit}
      />
      <DataTable
        header={header}
        value={users}
        responsive={true}
        resizableColumns={true}
        sortMode='multiple'
        dataKey='uid'
        paginator
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        // paginatorLeft={paginatorLeft}
        // paginatorRight={paginatorRight}

        globalFilter={globalFilter}
        selection={selectedUser}
        onSelectionChange={(e) => {
          setSelectedUser(e.value);
          toggle();
        }}
        selectionMode='single'
        dataKey='uid'
        emptyMessage='No users found.'>
        <Column field='company' header='Company Name' sortable={true} />
        <Column field='contact' header='Contact Name' sortable={true} />
        <Column field='email' header='email' sortable={true} />
        <Column field='uid' header='UserId' sortable={true} />
        <Column field='pricing' header='Pricing Type' sortable={true} />
        <Column field='axNumber' header='axNumber' sortable={true} />
        <Column
          field='isAdmin'
          header='Admin'
          body={roleTemplate}
          sortable={true}
        />
        <Column header='Send PW Reset Email' body={pwRestTemplate} />
      </DataTable>
    </div>
  );
};
const condition = (authUser) => authUser && authUser.isAdmin;

export default compose(withAuthorization(condition), withFirebase)(AdminPage);
