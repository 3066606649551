import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Input, Form, Button, Card, CardBody } from 'reactstrap';
import './forgot-password.css';

const ForgotPasswordPage = () => (
  <div className='container'>
    <h1>Forgot My Password</h1>
    <div className='row'>
      <div className='col'></div>
      <div className='col-sm-12 col-md-8 col-lg-5'>
        <Card>
          <CardBody>
            <ForgotPasswordForm />
          </CardBody>
        </Card>
      </div>
      <div className='col'></div>
    </div>
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class ForgotPasswordFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <div className='col-12' style={{ marginBottom: 10 }}>
            <Input
              className='form-control'
              name='email'
              value={email}
              onChange={this.onChange}
              type='text'
              placeholder='Email Address'
            />
          </div>

          <div className='col-12'>
            <Button
              className='form-control'
              color='primary'
              disabled={isInvalid}
              type='submit'>
              Request Password Reset
            </Button>
          </div>
          <div className='col-12' style={{ color: 'red' }}>
            {error && <p>{error.message}</p>}
          </div>
          <div className='col-12'>
            For help email:{' '}
            <a href='mailto:sales@capstonepg.com'>sales@capstonepg.com</a>.
          </div>
        </Form>
      </div>
    );
  }
}
const ForgotPasswordForm = compose(
  withRouter,
  withFirebase,
)(ForgotPasswordFormBase);

export default ForgotPasswordPage;
export { ForgotPasswordForm };
