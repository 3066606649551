import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Label,
  FormGroup,
} from 'reactstrap';
import InputMask from 'react-input-mask';

const EditUserModal = (props) => {
  const { modal, toggle, className, user, handleSubmit } = props;

  const [updatedUser, setUpdatedUser] = useState({
    contact: '',
    company: '',
    email: '',
    phone: '',
    isAdmin: false,
    pricing: 'Dealer',
    terms: 'Advanced-Pay',
    active: true,
    axNumber: '',
  });
  const [error, setError] = useState();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // console.log('user', user);
    user &&
      setUpdatedUser({
        ...user,
      });
    
  }, [user]);

  const handleOnChange = (event) => {
    setUpdatedUser({ ...updatedUser, [event.target.name]: event.target.value });
  };

  const onChangeCheckbox = (event) => {
    setUpdatedUser({
      ...updatedUser,
      [event.target.name]: event.target.checked,
    });
  };

  const isInvalid =
    // passwordOne !== passwordTwo ||
    // passwordOne === '' ||
    updatedUser.email === '' ||
    updatedUser.company === '' ||
    updatedUser.phone === '' ||
    updatedUser.contact === '';

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Edit User Info</ModalHeader>
        <ModalBody>
          <span
            style={{ fontSize: 'small', color: 'red', fontStyle: 'italic' }}>
            updating email address here does not change the user login login
          </span>
          <div className='row'>
            <div className='col'>
              <Form>
                <div className='row'>
                  <div className='col-12'>
                    <Input
                      name='company'
                      value={updatedUser.company}
                      onChange={handleOnChange}
                      type='text'
                      placeholder='Company Name'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <Input
                      name='contact'
                      value={updatedUser.contact}
                      onChange={handleOnChange}
                      type='text'
                      placeholder='Contact Name'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <InputMask
                      className='form-control'
                      name='phone'
                      value={updatedUser.phone}
                      onChange={handleOnChange}
                      type='tel'
                      mask='(999) 999-9999'
                      placeholder='Phone'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <Input
                      name='email'
                      value={updatedUser.email}
                      onChange={handleOnChange}
                      type='text'
                      placeholder='Email Address'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <Input
                      name='axNumber'
                      value={updatedUser.axNumber}
                      onChange={handleOnChange}
                      type='text'
                      placeholder='Ax Customer Number'
                    />
                  </div>
                </div>
                {/* <div className="row ">
                            <div className="col">
                                <Input
                                    name="passwordOne"
                                    value={passwordOne}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Password"
                                /></div>
                            <div className="col">
                                <Input
                                    name="passwordTwo"
                                    value={passwordTwo}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </div>

                        </div> */}

                <div className='row justify-content-center'>
                  <div className='col-4'>
                    <FormGroup>
                      <Input
                        type='select'
                        name='pricing'
                        id='pricing'
                        value={updatedUser.pricing}
                        onChange={handleOnChange}>
                        <option>Dealer</option>
                        <option>Dealer-NBS</option>
                        <option>Dealer-SportsInc</option>
                        <option>Employee</option>
                        <option>Industry</option>
                        <option>Junior</option>
                        <option>Outfitter</option>
                        <option>Preferred</option>
                        <option>Supported</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <FormGroup>
                      <Input
                        type='select'
                        name='terms'
                        id='terms'
                        value={updatedUser.terms}
                        onChange={handleOnChange}>
                        <option>Advanced-Pay</option>
                        <option>Net30</option>
                        <option>Other</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name='isAdmin'
                          type='checkbox'
                          checked={updatedUser.isAdmin}
                          onChange={onChangeCheckbox}
                        />{' '}
                        Is Admin
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name='active'
                          type='checkbox'
                          checked={updatedUser.active}
                          onChange={onChangeCheckbox}
                        />{' '}
                        Active
                      </Label>
                    </FormGroup>
                  </div>
                </div>

                {error && <p>{error.message}</p>}
              </Form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            disabled={isInvalid || processing}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(updatedUser);
            }}>
            Update User
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditUserModal;
