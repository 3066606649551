export const DEALER_PRICING = [
  'Dealer',
  'Dealer-NBS',
  'Dealer-SportsInc',
  'Industry',
  'Junior',
  'Outfitter',
  'Supported',
];

export const DEALER_MIN = ['Dealer', 'Dealer-NBS', 'Dealer-SportsInc'];
