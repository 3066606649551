import React, { useState, useEffect, useRef } from 'react';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { DataTable } from 'primereact/datatable';

import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import * as ROLES from '../../constants/roles';
import { Toast } from 'primereact/toast';
import './index.css';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const OrdersPage = ({ firebase }) => {
  const [orders, setOrders] = useState(undefined);
  const [ordersOpen, setOrdersOpen] = useState(undefined);
  const [ordersInProgress, setOrdersInProgress] = useState(undefined);
  const [ordersComplete, setOrdersComplete] = useState(undefined);
  const [selectedProducts3, setSelectedProducts3] = useState();
  // const [ordersFiltered, setOrdersFiltered] = useState(undefined);
  const [globalFilter, setGlobalFilter] = useState();
  const [expandedRows, setExpandedRows] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const dt = useRef(null);
  const toast = useRef(null);

  const statuses = ['Open', 'In Progress', 'Complete'];
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      await firebase.doGetOrdersListener(async (orderList) => {
        setOrders(orderList);
        setOrdersOpen(orderList.filter((o) => o.status === 'Open'));
        setOrdersComplete(orderList.filter((o) => o.status === 'Complete'));
        setOrdersInProgress(
          orderList.filter((o) => o.status === 'In Progress'),
        );
      });
    };
    loadData();
  }, [firebase]);

  const moneyTemplate = (rowData, column) => {
    return <span>${rowData[column.field]}</span>;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <Card>
          <CardBody>
            <div>Notes: {data.notes && data.notes}</div>
            <div>
              Catelogs:{' '}
              {data.catelogs && (
                <div>
                  Berger({data.catelogs.berger}) Lapua({data.catelogs.lapua})
                  SK(
                  {data.catelogs.sk}) VihtaVuori({data.catelogs.vihtavuori})
                </div>
              )}
            </div>
          </CardBody>
        </Card>

        <div className='p-grid p-fluid' style={{ padding: '2em 1em 1em 1em' }}>
          <DataTable
            value={data.orderItems}
            emptyMessage='No records found'
            selection={selectedProducts3}
            onSelectionChange={(e) => setSelectedProducts3(e.value)}
            dataKey={data.ItemNo}>
            <Column
              selectionMode='multiple'
              headerStyle={{ width: '3em' }}></Column>
            <Column field='ItemNo' header='Item Number' />
            <Column field='Description' header='Description' />
            {data.user.pricing === 'Dealer-Supported' && (
              <Column
                field='DealerPrice-Supported'
                header='Dealer Price/Supported'
                style={{
                  height: '.5em',
                  textAlign: 'center',
                  width: '120px',
                }}
                body={moneyTemplate}
              />
            )}
            {data.user.pricing === 'Preferred' && (
              <Column
                field='Preferred'
                header='Preferred'
                style={{
                  height: '.5em',
                  textAlign: 'center',
                  width: '120px',
                }}
                body={moneyTemplate}
              />
            )}
            <Column field='qty' header='qty' />
          </DataTable>
        </div>
      </>
    );
  };

  const getOrderItems = (order) => {
    setExpandedRows(order.data);
  };

  if (!orders) {
    return <div>loading...</div>;
  }

  const printable = ({ oid, user }) => {
    return (
      <Link to={`user/${user.uid}/order/${oid}`}>
        {' '}
        <span
          className='pi pi-window-maximize'
          style={{ fontSize: '2.5em' }}></span>
      </Link>
    );
  };

  const statusEditor = (props) => {
    return (
      <Dropdown
        value={props.value[props.rowIndex].status}
        options={statuses}
        onChange={(e) => onEditorValueChange(props, e.value)}
        style={{ width: '100%' }}
        placeholder='Select a status'
      />
    );
  };

  const onEditorValueChange = (props, value) => {
    let updateOrders = [...props.value];
    updateOrders[props.rowIndex][props.field] = value;

    const {
      oid,
      user: { uid },
    } = updateOrders[props.rowIndex];

    firebase
      .userOrder(uid, oid)
      .update({ status: value })
      .then(() => {
        setOrders(updateOrders);
        //          growl.current.show({ severity: 'success', summary: 'Success', detail: 'Status is updated' });
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Error updating status',
        });
        console.log(error);
      });
  };

  const salesOrderEditor = (props) => {
    return inputTextEditor(props, 'salesOrder');
  };

  const inputTextEditor = (props, field) => {
    return (
      <InputText
        type='text'
        value={props.rowData[field]}
        onChange={(e) => onTextEditorValueChange(props, e.target.value)}
      />
    );
  };

  const onTextEditorValueChange = (props, value) => {
    let updateOrders = [...props.value];
    updateOrders[props.rowIndex][props.field] = value;

    const {
      oid,
      user: { uid },
    } = updateOrders[props.rowIndex];

    firebase
      .userOrder(uid, oid)
      .update({ salesOrder: value })
      .then(() => {
        setOrders(updateOrders);
        //         growl.current.show({ severity: 'success', summary: 'Success', detail: 'Sales Order Id is updated' });
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Error updating Sales Order Id',
        });
        console.log(error);
      });
  };

  const header = (
    <div className='header row'>
      <div style={{ textAlign: 'left', marginLeft: '15px' }}>
        <i className='pi pi-search' style={{ margin: '4px 4px 0 0' }}></i>
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search Customer'
          size='50'
        />
      </div>
    </div>
  );

  const onStatusChange = (e) => {
    dt.current.filter(e.value, 'status', 'equals');
    setSelectedStatus(e.value);
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const statusFilter = (
    <Dropdown
      value={selectedStatus}
      options={statuses}
      onChange={onStatusChange}
      itemTemplate={statusItemTemplate}
      placeholder='Select a Status'
      className='p-column-filter'
      showClear
    />
  );

  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.total);
  };

  return (
    <div className='mx-5'>
      <div className=''>
        <div className='col-1'></div>
        <div className='col-10'>
          <h1>Orders</h1>
        </div>
      </div>
      <div className='orders'>
        <DataTable
          ref={dt}
          value={orders}
          responsive={true}
          editable={true}
          paginator={true}
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          header={header}
          globalFilter={globalFilter}
          expandedRows={expandedRows}
          onRowToggle={getOrderItems}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey='oid'
          sortMode='multiple'
          emptyMessage='No records found'>
          <Column expander={true} style={{ width: '3.5em' }} />
          <Column field='user.company' header='Company' sortable={true} />
          <Column field='user.contact' header='Name' sortable={true} />
          <Column field='user.email' header='Email' sortable={true} />
          <Column field='oid' header='Order ID' />
          <Column field='user.axNumber' header='axNumber' />
          <Column
            field='createDate'
            header='Create Date'
            sortable={true}
            sortFunction={(e) => {
              return orders.sort((a, b) => {
                if (e.order === 1) {
                  return new Date(a.createDate) - new Date(b.createDate);
                } else {
                  return new Date(b.createDate) - new Date(a.createDate);
                }
              });
            }}
          />
          <Column field='total' header='Order Total' body={priceBodyTemplate} />
          <Column
            field='status'
            header='Status'
            editor={statusEditor}
            filter
            filterElement={statusFilter}
          />
          <Column
            field='salesOrder'
            header='Sales Order ID'
            editor={salesOrderEditor}
          />
          <Column body={printable} header='PDF' />
        </DataTable>
      </div>
      <Toast ref={toast} position='bottom-right' />
    </div>
  );
};

const condition = (authUser) => authUser && authUser.isAdmin;

export default compose(withAuthorization(condition), withFirebase)(OrdersPage);
