import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';
import { DEALER_PRICING, DEALER_MIN } from '../../utils/dealer-pricing';

const VerifySubmitModal = (props) => {
  const {
    modal,
    toggle,
    className,
    submit,
    total,
    setNotes,
    notes,
    setCatelogCount,
    catelogCount,
    orderItems,
    pricing,
  } = props;

  const [submitModal, setSubmitModal] = useState(false);

  const hanldeChangeCatelogCount = (e) => {
    const newVal = { [e.target['name']]: e.target.value };
    setCatelogCount({
      ...catelogCount,
      ...newVal,
    });
  };

  console.log(submitModal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        {!submitModal && (
          <>
            <ModalHeader toggle={toggle}>Finalize Order</ModalHeader>
            <ModalBody>
              {DEALER_MIN.indexOf(pricing) !== -1 && total < 1500 && (
                <span style={{ color: 'red' }}>
                  <em>$1,500 min for free shipping and Haz</em>
                </span>
              )}
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>Description</th>
                    <th scope='col'>Qty</th>
                    <th scope='col'>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td style={{ textAlign: 'start' }}>
                          {item.Description}
                        </td>
                        <td className=''>{item.qty}</td>
                        <td className=''>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(
                            item.qty *
                              (DEALER_PRICING.indexOf(pricing) !== -1
                                ? item.DealerPrice
                                : item.PreferredPrice),
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colspan={3} style={{ textAlign: 'end' }}>
                      Total:{' '}
                      {total.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              {(DEALER_MIN.indexOf(pricing) !== -1 && total > 1000) ||
              (DEALER_MIN.indexOf(pricing) === -1 && total > 250) ? (
                <>
                  <Button className='btn btn-danger' onClick={toggle}>
                    Cancel
                  </Button>
                  <Button
                    className='btn btn-success'
                    onClick={() => setSubmitModal(true)}>
                    Next
                  </Button>
                </>
              ) : (
                <>
                  <div style={{ color: 'red' }}>
                    <em>min not met</em>
                  </div>
                  <Button className='btn btn-primary' onClick={toggle}>
                    Back
                  </Button>
                </>
              )}
            </ModalFooter>
          </>
        )}
        {submitModal && (
          <>
            <ModalHeader toggle={toggle}>Finalize Order</ModalHeader>
            <ModalBody>
              <div>
                <h6 style={{ marginBottom: 0 }}>
                  Your order total:{' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(total)}
                </h6>
                <span
                  style={{
                    fontSize: 'small',
                    fontWeight: 'lighter',
                    marginTop: -30,
                  }}></span>
              </div>
              <br />
              <div className>
                <div className='row'>
                  <div className='col-8'>
                    Please include the following catalogs with my order:{' '}
                  </div>
                  <div className='col-4'>How many?</div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-6'>Berger </div>
                  <div className='col-4'>
                    <Input
                      type='number'
                      min={0}
                      step={1}
                      style={{ width: '90px' }}
                      name='berger'
                      onChange={hanldeChangeCatelogCount}
                      placeholder='0'
                    />
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-6'>Lapua </div>
                  <div className='col-4'>
                    <Input
                      type='number'
                      min={0}
                      name='lapua'
                      step={1}
                      style={{ width: '90px' }}
                      onChange={hanldeChangeCatelogCount}
                      placeholder='0'
                    />
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-6'>SK </div>
                  <div className='col-4'>
                    <Input
                      type='number'
                      name='sk'
                      min={0}
                      step={1}
                      style={{ width: '90px' }}
                      onChange={hanldeChangeCatelogCount}
                      placeholder='0'
                    />
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-6'>VihtaVuori </div>
                  <div className='col-4'>
                    <Input
                      type='number'
                      name='vihtavuori'
                      min={0}
                      step={1}
                      style={{ width: '90px' }}
                      onChange={hanldeChangeCatelogCount}
                      placeholder='0'
                    />
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-12'>
                    <Input
                      type='textarea'
                      name='comments'
                      id='comments'
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder='Comments'
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button className='btn btn-danger' onClick={toggle}>
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => setSubmitModal(false)}>
                Back
              </button>{' '}
              <button className='btn btn-success' onClick={submit}>
                Submit Order
              </button>{' '}
            </ModalFooter>{' '}
          </>
        )}
      </Modal>
    </div>
  );
};

export default VerifySubmitModal;
