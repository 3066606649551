import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import Vihtavuori from './VihtaVuori';
import Berger from './Berger';
import Lapua from './Lapua';
import SK from './SK';
import { DEALER_PRICING } from '../../utils/dealer-pricing';
import './index.css';

const Order = ({
  setTotal,
  vihtavuoriData,
  bergerData,
  sKData,
  lapuaData,
  setVihtavuoriData,
  setSKData,
  setBergerData,
  setLapuaData,
  pricing,
}) => {
  // const DEALER_PRICING = [
  //   'Dealer',
  //   'Dealer-NBS',
  //   'Dealer-SportsInc',
  //   'Industry',
  //   'Junior',
  //   'Outfitter',
  //   'Supported',
  // ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [bergerCount, setBergerCount] = useState(0);
  const [lapuaCount, setLapuaCount] = useState(0);
  const [sKCount, setSKCount] = useState(0);
  const [vihtavuoriCount, setVihtavuoriCount] = useState(0);

  const totalHandler = () => {
    let vTotal = 0;
    let vCount = 0;
    vihtavuoriData.map((item) => {
      const price =
        DEALER_PRICING.indexOf(pricing) !== -1
          ? item.DealerPrice
          : item.PreferredPrice;
      vTotal = vTotal + (item.qty === undefined ? 0 : item.qty * price);
      vCount = vCount + Number(item.qty);
      setVihtavuoriCount(vCount);
      return item;
    });

    let bTotal = 0;
    let bCount = 0;
    bergerData.map((item) => {
      const price =
        DEALER_PRICING.indexOf(pricing) !== -1
          ? item.DealerPrice
          : item.PreferredPrice;
      bTotal = bTotal + (item.qty === undefined ? 0 : item.qty * price);
      bCount = bCount + Number(item.qty);
      setBergerCount(bCount);
      return item;
    });

    let sTotal = 0;
    let sCount = 0;
    sKData.map((item) => {
      const price =
        DEALER_PRICING.indexOf(pricing) !== -1
          ? item.DealerPrice
          : item.PreferredPrice;
      sTotal = sTotal + (item.qty === undefined ? 0 : item.qty * price);
      sCount = sCount + Number(item.qty);
      setSKCount(sCount);
      return item;
    });

    let lTotal = 0;
    let lCount = 0;
    lapuaData.map((item) => {
      const price =
        DEALER_PRICING.indexOf(pricing) !== -1
          ? item.DealerPrice
          : item.PreferredPrice;
      lTotal = lTotal + (item.qty === undefined ? 0 : item.qty * price);
      lCount = lCount + Number(item.qty);
      setLapuaCount(lCount);
      return item;
    });

    setTotal(
      Number(vTotal + bTotal + sTotal + lTotal)
    );
  };

  const handleUpateBergerQty = (index, value) => {
    bergerData[index].qty = Number(value).toString();
    setBergerData(bergerData);
    totalHandler();
  };

  const handleUpateSKQty = (index, value) => {
    sKData[index].qty = Number(value).toString();
    setSKData(sKData);
    totalHandler();
  };

  const handleUpateLapuaQty = (index, value) => {
    lapuaData[index].qty = Number(value).toString();
    setLapuaData(lapuaData);
    totalHandler();
  };

  const handleUpateVihtaVuoriQty = (index, value) => {
    vihtavuoriData[index].qty = Number(value).toString();
    setVihtavuoriData(vihtavuoriData);
    totalHandler();
  };

  return (
    <>
      <div className='products'>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={`Lapua(${lapuaCount})`}>
            <Lapua
              items={lapuaData}
              handleUpateLapuaQty={handleUpateLapuaQty}
              pricing={pricing}
            />
          </TabPanel>
          <TabPanel header={`SK(${sKCount})`}>
            <SK
              items={sKData}
              handleUpateSKQty={handleUpateSKQty}
              pricing={pricing}
            />
          </TabPanel>
          <TabPanel header={`Vihtavuori(${vihtavuoriCount})`}>
            <Vihtavuori
              items={vihtavuoriData}
              handleUpateVihtaVuoriQty={handleUpateVihtaVuoriQty}
              pricing={pricing}
            />
          </TabPanel>
          <TabPanel header={`Berger(${bergerCount})`}>
            <Berger
              items={bergerData}
              pricing={pricing}
              handleUpateBergerQty={handleUpateBergerQty}
            />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default Order;
