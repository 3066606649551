import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

import SignOutButton from '../SignOut';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import * as ROLES from '../../constants/roles';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar dark expand='md' sticky='top'>
      <NavbarBrand href='/'>
        <img
          src='/images/Capstone-logo-order-form.jpg'
          alt='Picture of the author'
          height='50px'
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <NavigationAuth authUser={authUser} />
            ) : (
              <NavigationNonAuth />
            )
          }
        </AuthUserContext.Consumer>
      </Collapse>
    </Navbar>
  );
};
const NavigationAuth = ({ authUser }) => {
  return (
    <>
      <Nav>
        <NavItem>
          <NavLink tag={Link} to={ROUTES.HOME}>
            Home
          </NavLink>
        </NavItem>

        {authUser.isAdmin && ( //OrdersPage
          <>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.ADMIN}>
                Admin
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.ORDERS}>
                Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.PRODUCTS}>
                Products
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
      <Nav className='ml-auto'>
        <NavItem>
          <SignOutButton />
        </NavItem>
      </Nav>
    </>
  );
};

const NavigationNonAuth = () => (
  <>
    {/* <Nav>
            <NavItem>
                <NavLink href={ROUTES.LANDING}>Landing</NavLink>
            </NavItem>
        </Nav> */}
    <Nav className='ml-auto'>
      {/* <NavItem >
                <NavLink href={ROUTES.SIGN_IN}>Sign In</NavLink>
            </NavItem> */}
    </Nav>
  </>
);
export default Navigation;
