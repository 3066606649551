import { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { TabView, TabPanel } from 'primereact/tabview';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

function ProductsPage({ firebase, authUser }) {
  const [vihtavuoriData, setVihtavuoriData] = useState([]);
  const [sKData, setSKData] = useState([]);
  const [bergerData, setBergerData] = useState([]);
  const [lapuaData, setLapuaData] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    fetchVihtData();
    fetchLapuaData();
    fetchBergerData();
    fetchSKData();
  }, []);

  const fetchVihtData = () => {
    firebase.vihtProducts().on('value', (snapshot) => {
      setVihtavuoriData(snapshot.val());
    });
  };

  const fetchLapuaData = () => {
    firebase.lapuaProducts().on('value', (snapshot) => {
      setLapuaData(snapshot.val());
    });
  };

  const fetchBergerData = () => {
    firebase.bergerProducts().on('value', (snapshot) => {
      setBergerData(snapshot.val());
    });
  };

  const fetchSKData = () => {
    firebase.skProducts().on('value', (snapshot) => {
      setSKData(snapshot.val());
    });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  function currencyFormatter(currency, sign) {
    // DealerPrice;
    var sansDec = parseFloat(currency).toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }

  const onCellValueChangedLapua = ({ rowIndex, data }) => {
    console.log(data);

    firebase
      .lapuaItem(rowIndex)
      .set({ ...data })
      .then(() => {
        console.log('then');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellValueChangedSK = ({ rowIndex, data }) => {
    console.log(data);

    firebase
      .skItem(rowIndex)
      .set({ ...data })
      .then(() => {
        console.log('then');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellValueChangedBerger = ({ rowIndex, data }) => {
    console.log(data);

    firebase
      .bergerItem(rowIndex)
      .set({ ...data })
      .then(() => {
        console.log('then');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellValueChangedVihtavori = ({ rowIndex, data }) => {
    console.log(data);

    firebase
      .vihtItem(rowIndex)
      .set({ ...data })
      .then(() => {
        console.log('then');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        className='products'
        style={{
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,
          height: '100%',
        }}>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={`Lapua`}>
            <div
              className='ag-theme-alpine'
              style={{
                height: '75vh',
                width: '100%',
                margin: 'auto',
              }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className='btn btn-danger m-1'
                  onClick={(_) => {
                    const selectedRows = gridApi.getSelectedRows();
                    gridApi.applyTransaction({ remove: selectedRows });
                  }}>
                  Delete Selected
                </button>
                <button
                  className='btn btn-primary m-1'
                  onClick={(data) => {
                    console.log(data);
                    gridApi.applyTransaction({ add: [{}] });
                    gridApi.paginationGoToLastPage();
                    gridApi.selectIndex(lapuaData.length, false, false);
                    // gridApi.ensureIndexVisible(lapuaData.length - 1);
                  }}>
                  Add A Row
                </button>
              </div>
              <AgGridReact
                pagination
                paginationAutoPageSize
                rowData={lapuaData}
                onCellValueChanged={onCellValueChangedLapua}
                onGridReady={onGridReady}
                stopEditingWhenCellsLoseFocus
                rowSelection='multiple'>
                <AgGridColumn
                  pinned='left'
                  checkboxSelection={true}
                  headerCheckboxSelection={true}
                  headerName=''
                  width={50}
                  field='checkboxBtn'></AgGridColumn>
                <AgGridColumn editable field='SubGroup'></AgGridColumn>
                <AgGridColumn
                  width={350}
                  editable
                  field='Description'></AgGridColumn>
                <AgGridColumn editable field='UPCCode'></AgGridColumn>
                <AgGridColumn editable field='ItemNo'></AgGridColumn>
                <AgGridColumn editable field='BoxCount'></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  field='CasePack'></AgGridColumn>
                <AgGridColumn
                  field='DealerPrice'
                  width={150}
                  editable
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.DealerPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='MSRPPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.MSRPPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='PreferredPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.PreferredPrice, '$')
                  }></AgGridColumn>
              </AgGridReact>
            </div>
          </TabPanel>
          <TabPanel header={`SK`}>
            <div
              className='ag-theme-alpine'
              style={{
                height: '75vh',
                width: '100%',
                margin: 'auto',
              }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className='btn btn-danger m-1'
                  onClick={(_) => {
                    const selectedRows = gridApi.getSelectedRows();
                    gridApi.applyTransaction({ remove: selectedRows });
                  }}>
                  Delete Selected
                </button>
                <button
                  className='btn btn-primary m-1'
                  onClick={(data) => {
                    console.log(data);
                    gridApi.applyTransaction({ add: [{}] });
                    gridApi.paginationGoToLastPage();
                    gridApi.selectIndex(lapuaData.length, false, false);
                    // gridApi.ensureIndexVisible(lapuaData.length - 1);
                  }}>
                  Add A Row
                </button>
              </div>
              <AgGridReact
                pagination
                paginationAutoPageSize
                rowData={sKData}
                onCellValueChanged={onCellValueChangedSK}
                onGridReady={onGridReady}
                stopEditingWhenCellsLoseFocus
                rowSelection='multiple'>
                <AgGridColumn
                  pinned='left'
                  checkboxSelection={true}
                  headerCheckboxSelection={true}
                  headerName=''
                  width={50}
                  field='checkboxBtn'></AgGridColumn>
                <AgGridColumn editable field='SubGroup'></AgGridColumn>
                <AgGridColumn
                  width={350}
                  editable
                  field='Description'></AgGridColumn>
                <AgGridColumn editable field='UPCCode'></AgGridColumn>
                <AgGridColumn editable field='ItemNo'></AgGridColumn>
                <AgGridColumn editable field='BoxCount'></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  field='CasePack'></AgGridColumn>
                <AgGridColumn
                  field='DealerPrice'
                  width={150}
                  editable
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.DealerPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='MSRPPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.MSRPPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='PreferredPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.PreferredPrice, '$')
                  }></AgGridColumn>
              </AgGridReact>
            </div>
          </TabPanel>
          <TabPanel header={`Vihtavuori`}>
            <div
              className='ag-theme-alpine'
              style={{
                height: '75vh',
                width: '100%',
                margin: 'auto',
              }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className='btn btn-danger m-1'
                  onClick={(_) => {
                    const selectedRows = gridApi.getSelectedRows();
                    gridApi.applyTransaction({ remove: selectedRows });
                  }}>
                  Delete Selected
                </button>
                <button
                  className='btn btn-primary m-1'
                  onClick={(data) => {
                    console.log(data);
                    gridApi.applyTransaction({ add: [{}] });
                    gridApi.paginationGoToLastPage();
                    gridApi.selectIndex(lapuaData.length, false, false);
                    // gridApi.ensureIndexVisible(lapuaData.length - 1);
                  }}>
                  Add A Row
                </button>
              </div>
              <AgGridReact
                pagination
                paginationAutoPageSize
                rowData={vihtavuoriData}
                onCellValueChanged={onCellValueChangedVihtavori}
                onGridReady={onGridReady}
                stopEditingWhenCellsLoseFocus
                rowSelection='multiple'>
                <AgGridColumn
                  pinned='left'
                  checkboxSelection={true}
                  headerCheckboxSelection={true}
                  headerName=''
                  width={50}
                  field='checkboxBtn'></AgGridColumn>
                <AgGridColumn editable field='SubGroup'></AgGridColumn>
                <AgGridColumn
                  width={350}
                  editable
                  field='Description'></AgGridColumn>
                <AgGridColumn editable field='UPCCode'></AgGridColumn>
                <AgGridColumn editable field='ItemNo'></AgGridColumn>
                <AgGridColumn editable field='BoxCount'></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  field='CasePack'></AgGridColumn>
                <AgGridColumn
                  field='DealerPrice'
                  width={150}
                  editable
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.DealerPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='MSRPPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.MSRPPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='PreferredPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.PreferredPrice, '$')
                  }></AgGridColumn>
              </AgGridReact>
            </div>
          </TabPanel>
          <TabPanel header={`Berger`}>
            <div
              className='ag-theme-alpine'
              style={{
                height: '75vh',
                width: '100%',
                margin: 'auto',
              }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className='btn btn-danger m-1'
                  onClick={(_) => {
                    const selectedRows = gridApi.getSelectedRows();
                    gridApi.applyTransaction({ remove: selectedRows });
                  }}>
                  Delete Selected
                </button>
                <button
                  className='btn btn-primary m-1'
                  onClick={(data) => {
                    console.log(data);
                    gridApi.applyTransaction({ add: [{}] });
                    gridApi.paginationGoToLastPage();
                    gridApi.selectIndex(lapuaData.length, false, false);
                    // gridApi.ensureIndexVisible(lapuaData.length - 1);
                  }}>
                  Add A Row
                </button>
              </div>
              <AgGridReact
                pagination
                paginationAutoPageSize
                rowData={bergerData}
                onCellValueChanged={onCellValueChangedBerger}
                onGridReady={onGridReady}
                stopEditingWhenCellsLoseFocus
                rowSelection='multiple'>
                <AgGridColumn
                  pinned='left'
                  checkboxSelection={true}
                  headerCheckboxSelection={true}
                  headerName=''
                  width={50}
                  field='checkboxBtn'></AgGridColumn>
                <AgGridColumn editable field='SubGroup'></AgGridColumn>
                <AgGridColumn
                  width={350}
                  editable
                  field='Description'></AgGridColumn>
                <AgGridColumn editable field='UPCCode'></AgGridColumn>
                <AgGridColumn editable field='ItemNo'></AgGridColumn>
                <AgGridColumn editable field='BoxCount'></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  field='CasePack'></AgGridColumn>
                <AgGridColumn
                  field='DealerPrice'
                  width={150}
                  editable
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.DealerPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='MSRPPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.MSRPPrice, '$')
                  }></AgGridColumn>
                <AgGridColumn
                  width={150}
                  editable
                  type='numericColumn'
                  field='PreferredPrice'
                  valueFormatter={(params) =>
                    currencyFormatter(params.data.PreferredPrice, '$')
                  }></AgGridColumn>
              </AgGridReact>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

const condition = (authUser) => authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(ProductsPage);
