import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
// import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Input, Form, Button, Card, CardBody } from 'reactstrap';
import './signon.css';
const SignInPage = () => (
  <div className='container'>
    <h1>Sign In</h1>
    <div className='row'>
      <div className='col'></div>
      <div className='col-sm-12 col-md-8 col-lg-5'>
        <Card>
          <CardBody>
            <SignInForm />
          </CardBody>
        </Card>
      </div>
      <div className='col'></div>
    </div>

    {/* <div className="row">
            <SignUpLink />
        </div> */}
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <div className='col-12' style={{ marginBottom: 10 }}>
            <Input
              className='form-control'
              name='email'
              value={email}
              onChange={this.onChange}
              type='text'
              placeholder='Email Address'
            />
          </div>
          <div className='col-12' style={{ marginBottom: 10 }}>
            <Input
              className='form-control'
              name='password'
              value={password}
              onChange={this.onChange}
              type='password'
              placeholder='Password'
            />
          </div>
          <div className='col-12'>
            <Button
              className='form-control'
              color='primary'
              disabled={isInvalid}
              type='submit'>
              Sign In
            </Button>
          </div>
          <div className='col-12' style={{ color: 'red' }}>
            {error && <p>{error.message}</p>}
          </div>
          <div className='col-12'>
            <Link to={ROUTES.PASSWORD_FORGET}> Forgot your password?</Link>
          </div>
          <br/>
          <div className='col-12'>
            For help email:{' '}
            <a href='mailto:sales@capstonepg.com'>sales@capstonepg.com</a>.
          </div>
        </Form>
      </div>
    );
  }
}
const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);
export default SignInPage;
export { SignInForm };
