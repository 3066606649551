import React from 'react';
import { withFirebase } from '../Firebase';
import { Button } from 'reactstrap';
const SignOutButton = ({ firebase }) => (
  <Button
    color='link'
    style={{
      marginTop: '5px',
      marginRight: '5px',
      color: 'white',
      '&hover': {
        color: 'gray',
      },
    }}
    type='button'
    onClick={firebase.doSignOut}>
    Sign Out
  </Button>
);
export default withFirebase(SignOutButton);
