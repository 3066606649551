import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

import * as ROLES from '../../constants/roles';

import './orderView.css';
import { Button } from 'reactstrap';

const OrderView = ({ match, firebase }) => {
  const { id, uid } = match.params;
  const [order, setOrder] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    firebase.user(uid).on('value', (snapshot) => {
      const user = snapshot.val();
      setUser(user);
      const order = user.orders[id];
      setOrder(order);
    });
  }, []);

  const printDocument = () => {
    const element = document.getElementById('toprint');
    const opt = {
      filename: `${id}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().set(opt).from(element).save();
  };

  const header = (
    <div className='header row' style={{ justifyContent: 'space-between' }}>
      <div className='row'>
        <div className='col'>Order Total: {order.total}</div>
        <div className='col'>
          Create Date: {new Date(order.createDate).toLocaleString()}
        </div>
        <div className='col'>status: {order.status}</div>
      </div>
      <div className='row'>
        {order.catelogs && (
          <div className='col'>
            Berger({order.catelogs.berger}) Lapua({order.catelogs.lapua}) SK(
            {order.catelogs.sk}) VihtaVuori({order.catelogs.vihtavuori})
          </div>
        )}
        <div className='col'>Notes: {order.notes && order.notes}</div>
      </div>
    </div>
  );

  const moneyTemplate = (rowData, column) => {
    return <span>${rowData[column.field]}</span>;
  };

  const dealerPricing = [
    'Dealer',
    'Dealer-NBS',
    'Dealer-SportsInc',
    'Industry',
    'Junior',
    'Outfitter',
    'Supported',
  ];

  return (
    <>
      <div className='row' style={{ flexFlow: 'column-reverse' }}>
        <Button color='success' onClick={printDocument}>
          Download PDF to Print
        </Button>
      </div>
      <div className='parent'>
        <div
          id='toprint'
          style={{
            backgroundColor: '#f5f5f5',
            width: '200mm',
            minHeight: '250mm',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <div className='div1'>
            <div>
              <h1>CapstonePG Order</h1>
            </div>
            <div>
              <h4>Order ID:{id}</h4>
              <hr />
              <h5>Contact Info:</h5>
              <div className='col-12'>Company: {user.company}</div>
              <div className='col-12'>Contact: {user.contact}</div>
              <div className='col-12'>Phone: {user.phone} </div>
              <div className='col-12'>Email: {user.email} </div>
              <div className='col-12'>axNumber: {user.axNumber} </div>
            </div>
          </div>
          <div className='div2'>
            <div
              className='p-grid p-fluid'
              style={{ padding: '2em 3em 1em 3em' }}>
              <DataTable
                value={order.orderItems}
                emptyMessage='No records found'
                header={header}>
                <Column
                  field='ItemNo'
                  header='Item Number'
                  style={{ width: '100px' }}
                />
                <Column
                  field='Description'
                  header='Description'
                  style={{ width: '200px' }}
                />
                {dealerPricing.indexOf(user.pricing) !== -1 ? (
                  <Column
                    field='DealerPrice'
                    header='Dealer Price'
                    style={{
                      height: '.5em',
                      textAlign: 'center',
                      width: '120px',
                    }}
                    body={moneyTemplate}
                  />
                ) : (
                  <Column
                    field='PreferredPrice'
                    header='Preferred'
                    style={{
                      height: '.5em',
                      textAlign: 'center',
                      width: '200px',
                    }}
                    body={moneyTemplate}
                  />
                )}
                <Column field='qty' header='qty' style={{ width: '100px' }} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const condition = (authUser) => authUser && authUser.isAdmin;

export default compose(withAuthorization(condition), withFirebase)(OrderView);
