import app from 'firebase/app';
// import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/performance';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    app.performance();
    this.createUser = app.app().functions().httpsCallable('createUser'); //.functions('us-central1');
  }

  createUser = this.createUser;
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            const dbUser = snapshot.val();
            // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = [];
            // }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = (uid) => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  bergerProducts = () => this.db.ref('Products/berger');
  bergerItem = (id) => this.db.ref(`Products/berger/${id}`);
  vihtProducts = () => this.db.ref('Products/vihtavouri');
  vihtItem = (id) => this.db.ref(`Products/vihtavouri/${id}`);
  lapuaProducts = () => this.db.ref('Products/lapua');
  lapuaItem = (id) => this.db.ref(`Products/lapua/${id}`);
  skProducts = () => this.db.ref('Products/sk');
  skItem = (id) => this.db.ref(`Products/sk/${id}`);

  userOrder = (uid, oid) => this.db.ref(`users/${uid}/orders/${oid}`);
  order = (oid) => this.db.ref(`orders/${oid}`);
  orders = async () => this.db.ref('orders');

  doGetOrdersListener = async (next) => {
    this.users().on('value', (snapshot) => {
      const users = snapshot.val();
      var orders = [];

      Object.keys(users).map((userKey) => {
        if (users[userKey].orders) {
          Object.keys(users[userKey].orders).map((key) => {
            let order = {
              user: { uid: userKey, ...users[userKey] },
              ...users[userKey].orders[key],
              oid: key,
            };
            order.createDate = new Date(order.createDate).toLocaleString();
            const itemlist = Object.keys(order.orderItems).map((itemkey) => {
              return {
                ...order.orderItems[itemkey],
              };
            });
            order.orderItems = itemlist;
            orders.push(order);
          });
        }
      });
      next(orders);
    });
  };
}
export default Firebase;
