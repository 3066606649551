import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import Order from '../Order/Order';
import { Button } from 'reactstrap';
import VerifySubmitModal from './VerifySubmitModal';

import Instructions from '../Order/Instructions';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import './index.css';

const HomePage = ({ firebase, authUser }) => {
  const toast = useRef(null);

  const [total, setTotal] = useState('$0.00');
  const [modal, setModal] = useState(false);
  const [panelCollapsed, setPanelCollapsed] = useState(true);
  const [vihtavuoriData, setVihtavuoriData] = useState([]);
  const [sKData, setSKData] = useState([]);
  const [bergerData, setBergerData] = useState([]);
  const [lapuaData, setLapuaData] = useState([]);

  const [catelogCount, setCatelogCount] = useState({
    berger: 0,
    lapua: 0,
    sk: 0,
    vihtavouri: 0,
  });
  const [notes, setNotes] = useState('');
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchVihtData();
    fetchLapuaData();
    fetchBergerData();
    fetchSKData();
  }, []);

  const fetchVihtData = () => {
    firebase.vihtProducts().on('value', (snapshot) => {
      setVihtavuoriData(snapshot.val());
    });
  };

  const fetchLapuaData = () => {
    firebase.lapuaProducts().on('value', (snapshot) => {
      setLapuaData(snapshot.val());
    });
  };

  const fetchBergerData = () => {
    firebase.bergerProducts().on('value', (snapshot) => {
      setBergerData(snapshot.val());
    });
  };

  const fetchSKData = () => {
    firebase.skProducts().on('value', (snapshot) => {
      setSKData(snapshot.val());
    });
  };

  const getOrderItemsFinal = () => {
    const orderItemsLapua = lapuaData.filter((item) => {
      return item.qty > 0;
    });
    const orderItemsViht = vihtavuoriData.filter((item) => {
      return item.qty > 0;
    });
    const orderItemsBurger = bergerData.filter((item) => {
      return item.qty > 0;
    });
    const orderItemsSK = sKData.filter((item) => {
      return item.qty > 0;
    });

    return [
      ...orderItemsLapua,
      ...orderItemsViht,
      ...orderItemsBurger,
      ...orderItemsSK,
    ];
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const orderItemsFinal = getOrderItemsFinal();

    firebase
      .userOrder(authUser.uid, uuidv4())
      .set({
        orderItems: orderItemsFinal,
        createDate: new Date().getTime(),
        total: total,
        catelogs: catelogCount,
        notes: notes,
        status: 'Open',
        salesOrder: '',
      })
      .then(() => {
        //TODO: refresh data here...

        toggle();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Order Sumbitted! Thanks! ',
        });
        setTotal('$0.00');
        fetchVihtData();
        fetchLapuaData();
        fetchBergerData();
        fetchSKData();
      })
      .catch(() => {
        toggle();
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Order submission Error! Contact Morgan... ',
        });
      });
  };

  return (
    <>
      {!modal && (
        <div className='sticky'>
          <div className='  totalBar ml-auto'>
            <span className='totalBar-item'>
              {total !== '$0.00'
                ? `Your Order Total: ${total.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}`
                : 'Add Products Below'}
            </span>

            <Button
              disabled={total === '$0.00'}
              color='success'
              className='totalBar-item'
              onClick={toggle}>
              Submit
            </Button>
          </div>
        </div>
      )}
      <div className='mx-5'>
        <Toast ref={toast} position='bottom-right' />
        <div className='row flex mt-0 mb-0 '>
          <VerifySubmitModal
            setCatelogCount={setCatelogCount}
            catelogCount={catelogCount}
            setNotes={setNotes}
            notes={notes}
            toggle={toggle}
            modal={modal}
            submit={handleSubmit}
            orderItems={getOrderItemsFinal()}
            total={total}
            pricing={authUser.pricing}
          />
        </div>
        <div className='row'>
          <div className='col'>
            <h2>Online Order</h2>
            <p>Welcome to the Capstone Online Order Portal!</p>
            <p>
              Using the Online Portal will allow us to process your order
              quicker and it also provides you with an immediate order
              confirmation. The Portal is just an online order form- it does not
              reflect our available inventory or due dates. For the most part,
              we have <u>no available inventory</u> and due to ever-changing
              production and import schedules,{' '}
              <u>we cannot provide due dates</u> at this time.
            </p>
          </div>
        </div>

        <div className='row mt-0'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              marginTop: '20px',
            }}>
            <Instructions />

            <Card style={{ padding: 0, margin: '10px' }}>
              <strong>My Information</strong>
              <div className='col-12'>Company: {authUser.company}</div>
              <div className='col-12'>Contact: {authUser.contact}</div>
              <div className='col-12'>Phone: {authUser.phone} </div>
              <div className='col-12'>Email: {authUser.email} </div>
            </Card>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <img
              src='/images/Capstone-brands.jpg'
              alt='Capstone Brand Images'
            />
          </div>
        </div>
        <Order
          total={total}
          setTotal={setTotal}
          vihtavuoriData={vihtavuoriData}
          bergerData={bergerData}
          sKData={sKData}
          lapuaData={lapuaData}
          setVihtavuoriData={setVihtavuoriData}
          setSKData={setSKData}
          setBergerData={setBergerData}
          setLapuaData={setLapuaData}
          pricing={authUser.pricing}
        />
      </div>
    </>
  );
};

const condition = (authUser) => authUser;

export default compose(withAuthorization(condition), withFirebase)(HomePage);
