import React from 'react';
import { Modal,ModalHeader,ModalFooter,ModalBody, Button } from 'reactstrap'

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {

    return (
        <Modal isOpen={showModal} toggle={handleClose} >
            <ModalHeader toggle={handleClose}>You Have Been Idle!</ModalHeader>
            <ModalBody>
                You Will Get Timed Out. You want to stay?
        </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleClose}>Stay</Button>{' '}
                <Button color="danger" onClick={handleLogout}>Logout
                </Button>
            </ModalFooter>
        </Modal>
    )
}