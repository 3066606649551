import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
const Lapua = ({ items, handleUpateLapuaQty, pricing }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const subHeaderTemplate = (data) => {
    return (
      <>
        <h5>{data.SubGroup}</h5>
      </>
    );
  };
  const [globalFilter, setGlobalFilter] = useState(null);
  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Global Search'
        />
      </span>
    </div>
  );

  const footerTemplate = (data, index) => {
    return <React.Fragment></React.Fragment>;
  };

  const moneyTemplate = (rowData, column) => {
    return <span>${rowData[column.field]}</span>;
  };

  const dealerPricing = [
    'Dealer',
    'Dealer-NBS',
    'Dealer-SportsInc',
    'Industry',
    'Junior',
    'Outfitter',
    'Supported',
  ];

  return (
    <DataTable
      value={items}
      editable={true}
      scrollable={true}
      // scrollHeight='300px'

      rowGroupMode='subheader'
      groupField='SubGroup'
      globalFilter={globalFilter}
      header={header}
      rowGroupHeaderTemplate={subHeaderTemplate}
      rowGroupFooterTemplate={footerTemplate}>
      <Column
        field='ItemNo'
        header='Item #'
        style={{
          height: '.2em',
          textAlign: 'center',
          width: '80px',
          wordWrap: 'breakWord',
        }}
      />
      <Column
        field='Description'
        header='Description'
        style={{ height: '.5em', textAlign: 'center', width: '300px' }}
      />
      <Column
        field='MSRPPrice'
        header='MSRP Price'
        style={{ height: '.5em', textAlign: 'center', width: '80px' }}
        body={moneyTemplate}
      />

      {dealerPricing.indexOf(pricing) !== -1 ? (
        <Column
          field='DealerPrice'
          header={pricing}
          style={{ height: '.5em', textAlign: 'center', width: '80px' }}
          body={moneyTemplate}
        />
      ) : (
        <Column
          field='PreferredPrice'
          header={pricing}
          style={{ textAlign: 'center', width: '80px' }}
          body={moneyTemplate}
        />
      )}
      <Column
        field='BoxCount'
        header='Box Count'
        style={{ height: '.5em', width: '60px' }}
      />
      {/* <Column field="UPCCode" header="UPC Code" style={{ height: '.5em', textAlign: 'center', width: '100px' }} /> */}
      <Column
        field='BoxWeight'
        header='Box Weight'
        style={{ height: '.5em', textAlign: 'center', width: '80px' }}
      />
      {/* <Column field="Dimensions" header="Dimensions  L x W x H" style={{ height: '.5em', textAlign: 'center', width: '115px' }} /> */}
      <Column
        field='qty'
        header='Quantity'
        style={{
          backgroundColor: 'red',
          textAlign: 'center',
          width: '100px',
          color: 'white',
          fontWeight: 'bold',
        }}
        editor={(context) => {
          return (
            <input
              style={{ width: '80%' }}
              type='number'
              value={Number(items[context.rowIndex].qty).toString()}
              min={0}
              step={1}
              onChange={(e) => {
                handleUpateLapuaQty(context.rowIndex, e.target.value);
              }}
            />
          );
        }}
      />
    </DataTable>
  );
};

export default Lapua;
