import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import ForgotPasswordPage from '../ForgotPassword';
import HomePage from '../Home';
import AdminPage from '../Admin';
import OrdersPage from '../Orders';
import { IdleTimeOutModal } from './IdeTimeOutModal';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import OrderView from '../OrderView/OrderView';
import ProductsPage from '../Products';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const App = (props) => {
  let idleTimer = useRef(null);
  const [timeout, setTimeout] = useState(1000 * 5 * 1);
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimeOut] = useState(false);

  const onAction = (e) => {
    setIsTimeOut(false);
  };

  const onActive = (e) => {
    setIsTimeOut(false);
  };

  const onIdle = (e) => {
    console.log('user is idle', e);

    if (isTimedOut) {
      props.firebase.doSignOut();
      setShowModal(false);
    } else {
      setShowModal(true);
      idleTimer.reset();
      setIsTimeOut(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    setShowModal(false);
    props.firebase.doSignOut();
  };

  return (
    <>
      {/* <IdleTimer
                        ref={ref => { idleTimer = ref }}
                        element={document}
                        onActive={onActive}
                        onIdle={onIdle}
                        onAction={onAction}
                        debounce={250}
                        timeout={timeout} /> */}

      <Router>
        <Navigation />
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.ORDERS} component={OrdersPage} />
        <Route path={ROUTES.PRODUCTS} component={ProductsPage} />
        <Route path={ROUTES.ORDER_VIEW} component={OrderView} />
        <Route path={ROUTES.PASSWORD_FORGET} component={ForgotPasswordPage} />
      </Router>

      <IdleTimeOutModal
        showModal={showModal}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default withAuthentication(App);
