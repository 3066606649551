import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import InputMask from 'react-input-mask';

import * as ROLES from '../../constants/roles';

import { FirebaseContext } from '../Firebase';
import { withFirebase } from '../Firebase';
import { Input, Form, Label, FormGroup, Button } from 'reactstrap';

import './index.css';

const SignUpPage = () => (
  <div className='container'>
    <h3>New User Form</h3>
    <FirebaseContext.Consumer>
      {(firebase) => <SignUpForm firebase={firebase} />}
    </FirebaseContext.Consumer>
  </div>
);

const INITIAL_STATE = {
  contact: '',
  company: '',
  email: '',
  phone: '',
  passwordOne: '#1RandomP@$$Sucka',
  //  passwordTwo: '',
  isAdmin: false,
  error: null,
  processing: false,
  pricing: 'Dealer',
  terms: 'Advanced-Pay',
  axNumber: '',
  active: true
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      contact,
      email,
      passwordOne,
      company,
      phone,
      isAdmin,
      pricing,
      terms,
      axNumber,
    } = this.state;

    this.setState({ processing: true });

    this.props.firebase
      .createUser({
        email,
        password: passwordOne,
        name: contact,
        pricing,
        terms,
        axNumber,
      })
      .then(({ data }) => {
        return this.props.firebase.user(data.uid).set({
          contact,
          company,
          phone,
          email,
          isAdmin,
          active: true,
          pricing,
          terms,
          axNumber,
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(function (error) {
        console.log(error);
        this.setState({ processing: false });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      contact,
      company,
      phone,
      email,
      // passwordOne,
      // passwordTwo,
      isAdmin,
      processing,
      error,
      pricing,
      terms,
      axNumber,
    } = this.state;

    const isInvalid =
      // passwordOne !== passwordTwo ||
      // passwordOne === '' ||
      email === '' || company === '' || phone === '' || contact === '';

    return (
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-6'>
          <Form onSubmit={this.onSubmit}>
            <div className='row'>
              <div className='col-12'>
                <Input
                  name='company'
                  value={company}
                  onChange={this.onChange}
                  type='text'
                  placeholder='Company Name'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Input
                  name='contact'
                  value={contact}
                  onChange={this.onChange}
                  type='text'
                  placeholder='Contact Name'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <InputMask
                  className='form-control'
                  name='phone'
                  value={phone}
                  onChange={this.onChange}
                  type='tel'
                  mask='(999) 999-9999'
                  placeholder='Phone'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Input
                  name='email'
                  value={email}
                  onChange={this.onChange}
                  type='text'
                  placeholder='Email Address'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Input
                  name='axNumber'
                  value={axNumber}
                  onChange={this.onChange}
                  type='text'
                  placeholder='Ax Customer Number'
                />
              </div>
            </div>
            {/* <div className="row ">
                            <div className="col">
                                <Input
                                    name="passwordOne"
                                    value={passwordOne}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Password"
                                /></div>
                            <div className="col">
                                <Input
                                    name="passwordTwo"
                                    value={passwordTwo}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </div>

                        </div> */}

            <div className='row justify-content-center'>
              <div className='col-4'>
                <FormGroup>
                  <Input
                    type='select'
                    name='pricing'
                    id='pricing'
                    value={pricing}
                    onChange={this.onChange}>
                    <option>Dealer</option>
                    <option>Dealer-NBS</option>
                    <option>Dealer-SportsInc</option>
                    <option>Employee</option>
                    <option>Industry</option>
                    <option>Junior</option>
                    <option>Outfitter</option>
                    <option>Preferred</option>
                    <option>Supported</option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup>
                  <Input
                    type='select'
                    name='terms'
                    id='terms'
                    value={terms}
                    onChange={this.onChange}>
                    <option>Advanced-Pay</option>
                    <option>Net30</option>
                    <option>Other</option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup check>
                  <Label check>
                    <Input
                      name='isAdmin'
                      type='checkbox'
                      checked={isAdmin}
                      onChange={this.onChangeCheckbox}
                    />{' '}
                    Is Admin
                  </Label>
                </FormGroup>
              </div>
            </div>

            {/* <fieldset style={{}}>
                            <legend>Customer Type</legend>
                            <div className="row">
                                <div className="col-6">
                                    <RadioButton inputId="rb1" name="pricing" value="Dealer-Supported" onChange={(e) => this.setState({ pricing: e.value })} checked={this.state.pricing === 'Dealer-Supported'} />
                                    <label htmlFor="rb1" className="p-radiobutton-label">Dealer-Supported</label>
                                </div>
                                <div className="col-6">
                                    <RadioButton inputId="rb2" name="pricing" value="Preferred" onChange={(e) => this.setState({ pricing: e.value })} checked={this.state.pricing === 'Preferred'} />
                                    <label htmlFor="rb2" className="p-radiobutton-label">Preferred</label>
                                </div>
                            </div>
                        </fieldset> */}

            <div className='row justify-content-end'>
              <Button
                color='success'
                disabled={isInvalid || processing}
                type='submit'>
                Create User
              </Button>
            </div>

            {error && <p>{error.message}</p>}
          </Form>
        </div>
        <div className='col-3'></div>
      </div>
    );
  }
}
// const SignUpLink = () => (
//   <p>
//     Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
//   </p>
// );

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;
export { SignUpForm };
